.form label {
    font-weight: 600;
}

.form .container {
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 10px 25px 25px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.form .container-no-border {
    margin-bottom: 25px;
    padding: 10px 25px 25px 10px;
}

.narrow input {
    height: 0.5rem;
}

.carrier-select label {
    font-weight: 600;
}

.carrier-select {
    width: 50rem;
}

.upper input {
    text-transform: uppercase;
}

.error {
    color: red;
}